.ChartTiles {
  .ant-menu-horizontal {
    line-height: 30px;
  }
  .ChartTile {
    text-align: center;
    img {
      margin-top: 5px;
      width: 28px;
    }

    p {
      color: #516e90;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
    }
  }
}

.FilterSection {
  min-height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 7px;

  button {
    height: 100%;
  }

  .ant-tag {
    margin-top: 0.45rem;
    // margin-right: 0.4rem;
  }
}
