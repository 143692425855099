.FileUploadContainer {
  background-color: $color-bg;
  color: #516e90;
  position: relative;
  padding: 2rem;
  min-height: calc(100vh - 64px);
}

.UploadedFileCard {
  background-color: white;
  min-height: 330px;
  display: block;
  margin-bottom: 1.5rem;
  padding: 1.4rem;

  h3 {
    word-wrap: break-word;
  }

  .UploadedFileCard--delete {
    position: absolute;
    top: 12px;
    right: 20px;

    i {
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .UploadedFileCard--Icon {
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    svg {
      width: 35px;
      height: 35px;
      path {
        fill: white;
      }
    }
  }
}
