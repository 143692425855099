* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  // margin-bottom: 0 !important;
}

input[disabled] {
  pointer-events: none;
}

body,
html,
#root,
.Main {
  padding: 0;
  margin: 0;
  height: 100%;
  color: black;
}

.appPages {
  position: absolute;
  padding: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: inherit;
}

.AppHeader {
  padding: 0 !important;
  background: white !important;
  border-bottom: 1px solid rgba($color-blue, 0.1);
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  .AppHeader-right {
    padding-right: 20px;
  }

  .AppHeader-left {
    display: flex;

    .logo {
      width: 60px;
      height: 64px;
      background: white;
      // margin-right: 20px;
      border-bottom: 1px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: royalblue;
        }
      }
    }
  }
}

.App {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
}

.AppBody {
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
}

.AppMain {
  display: flex;
  margin-top: 64px;

  .AppContent {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 64px);
  }

  .fullscreen {
    @extend .AppContent;
    position: fixed;
    padding: 20px;
    left: 0;
    top: 0;
    width: 100%;
    background-color: white;
    min-height: 100vh;
  }
}

.AppSidebar {
  box-shadow: inset -7px 0 18px -15px rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(to right,
      $color-blue 60px,
      $color-off-white 60px) !important;
  position: relative;
}

.Sidebar {
  width: 250px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    &:first-child {
      margin-top: 1rem;
    }
  }

  a {
    text-decoration: none;
    display: block;
    line-height: 32px;
    padding: 10px 0px;
    color: $color-grey;
  }

  i {
    color: white !important;
  }
}

.activeLink {
  background: white;
  color: $color-blue !important;

  i {
    color: $color-blue !important;
  }
}

.menu {
  position: absolute;
  bottom: 40px;
  left: 15px;
  height: 20px;
  width: 20px;
  z-index: 1000;

  &:hover {
    cursor: pointer;
  }
}

.Main {
  flex: 1;
  position: relative;
}

.react-grid-item {
  background: #fff;
  border-radius: 2px;
  border: 1px dashed $color-blue-grey;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    .react-resizable-handle {
      visibility: visible;
    }

    .dashWidget--settingsIcon {
      visibility: visible;
    }
  }

  .react-resizable-handle {
    visibility: hidden;
    transition: 0.2s ease-in;
  }

  .dashWidget--settingsIcon {
    visibility: hidden;
    transition: 0.2s ease-in;
  }
}

.removeDashes {
  border: 1px solid rgba($color-blue-grey, 0.2) !important;

  .dashWidget--Card_Footer {
    border-bottom: 1px solid rgba($color-blue-grey, 0.2) !important;
  }
}

.ant-select {
  width: 100%;
}

.btn-outline-secondary {
  border: none !important;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.ant-modal-footer {
  border: none;
}

.ant-modal-header,
.ant-modal-header {
  border: none !important;
}

.ant-modal-close {
  top: 17px !important;
  right: 24px !important;
}

.ant-modal-close-x {
  background: rgba($color-blue, 0.2);
  border-radius: 50px;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;

  i {
    color: $color-blue !important;
    font-size: 10px !important;
    font-weight: bold !important;
  }
}

.ant-modal-title {
  font-weight: bold !important;
}

.ant-radio-button-wrapper-checked {
  border: 1px solid #d9d9d9 !important;
  border-bottom: 2px solid $color-blue !important;
  box-shadow: none !important;
}

table {
  margin-bottom: 0 !important;

  .dx-g-bs4-table-cell {
    padding: 0.45rem;
  }
}