.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.position-relative {
  position: relative !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.TableFilterContent {
  border: 1px solid $color-grey;
  padding: 0.75rem;
  height: 176px;
  width: 320px;
  overflow: auto;
}

.TableFilterFooter {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  button {
    &:first-child {
      margin-right: 10px;
    }
  }
}
.AnalyticsTable__DatasetSelect {
  .ant-select-dropdown-menu-item {
    line-height: 22px;
    white-space: initial;
    text-overflow: initial;
  }

  .subform-item {
    background-image: url(../images/line.png);
    background-position: center left 15px;
    background-repeat: no-repeat;
    position: relative;
    padding-left: 35px;
  }

  .last-sub {
    @extend .subform-item;
    background-image: url(../images/line_short.png);
  }
}

.AnalyticsTable {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  // height: calc(100vh - 96px);

  .py-5 {
    text-align: center;
    padding: 1rem;
  }

  .AnalyticsTable__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    background-color: #f6f6f6;
    padding: 0.85rem;

    .ant-select-selection {
      border: none;
      background-color: transparent;
    }
  }
  .dx-g-bs4-table-container {
    height: calc(100vh - 142px) !important;
  }
}
