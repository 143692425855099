.HomeContainer {
  height: inherit;
}
.HomeContainer-Row {
  margin: 2.5rem 0 !important;
  .ant-upload.ant-upload-drag {
    // max-width: 600px;
    text-align: left;
    padding: 1.5rem;
    background: transparent;
    position: relative;
  }

  .ant-upload-list {
    position: absolute;
    bottom: 0;
    width: 95%;
  }
}

// .HomeContainer-Row .ant-upload.ant-upload-drag

.HomeLeftDiv {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;

  .HomeLeftDiv-svg > svg {
    width: 35px !important;
    height: 35px !important;
    path {
      fill: $color-blue;
    }
  }

  p {
    margin: 0 1rem;
  }
}

.HomeRightDiv {
  // max-width: 600px;
  padding: 1.5rem;
  border: 1px solid rgba($color-blue, 0.1);

  .header {
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 4px 16px;
    padding-left: 15px;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 0;
  }
}

.HomeRightDiv-Video {
  // max-width: 600px;
  padding: 1.5rem;
  border: 1px solid rgba($color-blue, 0.1);
  display: flex;
  flex-wrap: wrap;

  .videoBox {
    width: 200px;
    height: 125px;
    // background: #abc;
    background: url('../images/jpg/maxresdefault.jpg') no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .videoBox-info {
    flex: 1;
    padding: 0 1rem;
  }
}

.video-modal {
  width: 640px !important;
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    color: $color-blue;
    top: 10px;
    right: 10px;
  }

  .ant-modal-close-x {
    background: rgba($color-blue, 0.2);
    border-radius: 50px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.uploadDataset-modal {
  .ant-modal-body {
    padding: 2.5rem !important;
  }

  .ant-modal-header {
    background-color: $color-blue;
    color: white !important;
  }
}

.uploadDataset--Body {
  display: flex;
  padding: 1rem !important;

  .numIndicator {
    position: absolute;
    left: 15px;
    top: 24px;
    color: $color-blue;
    font-size: 0.65rem;
    font-weight: bold;
  }

  p {
    font-weight: bold;
    margin: 0;
  }

  .uploadDataset--BodyLeft {
    position: relative;
    margin-right: 20px;
  }
}
