.VisualizeContainer {
  .VisualizeMain {
    width: calc(100% - 320px);
  }

  .hide-chart {
    visibility: hidden;
    display: none;
  }

  .VisualizeMain--chart {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;

    .NumberValue {
      font-size: 2rem;
      color: #516e90;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      flex-direction: column;

      .subscript {
        font-size: 2rem;
      }
    }
  }
  .VisualizeSideBar {
    position: absolute;
    right: 0;
    top: 0;
    width: 320px;
    height: 100%;
    border-left: 1px solid rgb(211, 228, 239);
    border-right: 1px solid rgb(211, 228, 239);
  }

  .VisualizeConfigForm {
    .labelDiv {
      color: $color-blue;
      padding: 0.5rem 0;
    }
    .formSection {
      margin-bottom: 1.5rem;
    }
    .formSectionLabel {
      color: $color-blue;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0.8rem 0;
    }
    .DatasetSelectDiv {
      background-color: #f5f5f5;
      padding: 1rem;
      height: 120px;
    }

    .ConfigForm {
      padding: 1rem;
    }
  }
}
