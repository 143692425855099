.DatasetViewTable {
  .rt-resizable-header-content {
    text-align: left !important;
    font-weight: bold;
  }
  .ReactTable .rt-thead {
    box-shadow: none !important;
  }

  .rt-thead {
    background: white;
    color: black;
    box-shadow: none !important;
    border-bottom: 1px solid $color-grey;

    // padding: 2px 15px !important;
  }
}

.advOptionChartCard {
  display: flex;
  min-height: 60px;
  border: 1px dashed $color-blue-grey;
  align-items: center;
  padding: 0.5rem 0.7rem;
  margin-bottom: 1rem;

  p {
    margin-left: 1rem;
    margin-bottom: 0;
    font-weight: 700;
  }
}

.dataChartCard {
  @extend .advOptionChartCard;
  min-height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 1rem;
  }
  p {
    margin: 0;
  }
}
