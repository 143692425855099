.ChartConfigForm {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  label {
    color: $color-blue-grey;
    font-weight: 600 !important;
    // margin: 1.2rem 0rem 0.5rem;
  }

  .ant-col {
    position: relative;
  }

  .labelDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
  }

  .ChartConfigForm--CustomLabel {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px !important;
  }
}

.ChartConfigFormErrors {
  position: absolute;
  bottom: 53px;
  color: red;
  right: 16px;
}

.ChartConfigModal {
  .ant-modal {
    width: 750px !important;
  }
}

.ChartConfigForm_dashed {
  border: 1px dashed $color-blue-grey;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.ChartConfigContainer {
  display: flex;
  align-items: center;
  .ChartConfigFormDiv {
    flex: 1;
    width: 100%;
  }

  .ChartConfigIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    padding-left: 1rem;

    svg {
      width: 200px !important;
    }
  }
}

.MenuGrid {
  background-color: white;
  width: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 0.4rem;
}

.MenuGridList {
  display: inline-block;
  width: 50%;
  padding: 10px 0;
  transition: 0.2s ease-in;

  &:hover {
    cursor: pointer;
    background-color: rgba($color-bg, 1);
  }

  // &:nth-child(even) {
  //   border-bottom: 1px solid rgba($color-blue-grey, 0.2);
  //   border-left: 1px solid rgba($color-blue-grey, 0.2);
  // }
  // &:nth-child(odd) {
  //   border-bottom: 1px solid rgba($color-blue-grey, 0.2);
  // }

  // &:nth-child(5n),
  // &:nth-child(6n) {
  //   border-bottom: 0;
  // }
}

.MenuGridItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  i {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
}
