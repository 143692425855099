@import './_variables.scss';

@import './components/_dashWidgets.scss';
@import './components/_ChartConfigForm.scss';
@import './components/_ChartTiles.scss';
@import './_bootstrapTable.scss';

@import './containers/_Home.scss';
@import './containers/_Visualize.scss';
@import './containers/_FileUpload.scss';
@import './containers/_DatasetView.scss';

@import '../../node_modules/@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
@import '../../node_modules/react-grid-layout/css/styles.css';
@import '../../node_modules/react-resizable/css/styles.css';

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/popover';
@import '../../node_modules/bootstrap/scss/card';
// @import '../../node_modules/bootstrap/scss/buttons';

@import './_overrides.scss';

.PageHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;

  h4 {
    margin: 0;
  }
}

.table-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insyt-loader {
  border: 2px solid $color-blue;
  /* Light grey */
  border-top: 2px solid transparent;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.SeedingLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;

  p {
    margin: 0;
    font-size: 1.1rem;
  }
}

.CustomSelect {
  .CustomSelect-Cover {
    background-color: transparent;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
  }

  position: relative;

  .fakeInput {
    border: 1px solid #d9d9d9;
    padding: 5px;
    padding-bottom: 0;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .ant-tag {
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px;
    }

    input {
      display: inline-block !important;
      border: none;
      outline: none;
      z-index: 1090;

      // height: inherit;
    }
  }

  .CustomSelect--Dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    position: fixed;

    z-index: 1090;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 300px;

    ul {
      list-style: none;
      margin: 0;
      max-height: 300px;
      overflow: auto;

      li {
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          visibility: hidden;
        }

        span {
          // width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: #d8e4f0;
          cursor: pointer;

          i {
            visibility: visible;
          }
        }
      }

      .active {
        font-weight: bold;
        background-color: #f6f6f6;

        i {
          visibility: visible;
        }
      }
    }
  }
}

.dash_title {
  span {
    display: none;
  }

  &:hover {
    span {
      display: inline;
    }
  }
}

