.dashWidget {
  width: 100%;
  height: inherit;
}

.chartLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: #e6e6e6;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.dashWidget--Card {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #E5E5E5 !important;

  .dashWidget--Card__Handler {
    &:hover {
      cursor: move !important;
    }
  }

  .dashWidget--Card__OptionsRow {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    // margin-bottom: 1rem;

    div>spans {
      text-transform: uppercase;
    }
  }

  .NumberValue span {
    font-size: 2.5rem;
  }

  .dashWidget--Card_Empty {
    text-align: center;

    span {
      &:first-child {
        color: $color-blue-grey;
      }

      &:last-child {
        color: $color-blue;
        font-weight: 600;
        font-size: 1.15rem;
        margin-top: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .dashWidget--Card_Header {
    background-color: transparent;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 40px;

    .dashWidget--settingsIcon {
      visibility: hidden;
    }
  }

  .dashWidget--Card_Body {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    p {
      font-weight: 600;
      font-size: 1.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
    }
  }

  .dashWidget--Card_Footer {
    flex: 1;
    border-bottom: 1px dashed $color-blue-grey;
  }
}

.widgetTableContainer {
  position: relative;
  height: inherit;
  overflow: hidden;
  padding: 0 1rem;

  .table thead tr th {
    color: #505050;
    text-transform: capitalize;
    background-color: #F4F7F9;
    border-bottom: 0px;
  }
}

.widgetnumberContainer {
  .dashWidget--Card_Body {
    p {
      font-size: 44px;
    }
  }
}

.removeDashes {
  box-shadow: 0px 3px 6px #0000000A !important;
  border: 1px solid #E5E5E5 !important;
  border-radius: 3px !important;
}